import { useState } from "react";
import Input from "../Forms/Input";

import { setDoc, Timestamp, doc } from "firebase/firestore";
import { db } from "../../firebase";

import form from "../../lib/jobApplication.json";
import Textarea from "../Forms/Textarea";

const JobApply = ({ id, uid, email }) => {
  const [dirty, setDirty] = useState(false);
  const [formData, setFormData] = useState({
    nickname: "",
    fit: false,
    age: "",
    country: "",
    launguages: "",
    resume: "",
    samples: "",
    highlights: "",
    why_hire: "",
    linkedin: "",
    socials: "",
    start_date: false,
    hours: "",
    additional_info: "",
    share_approval: false,
    salary: "",
    additional_info_private: "",
  });
  const [err, setErr] = useState({});
  const [saving, setSaving] = useState(false);
  const [applied, setApplied] = useState(false);

  const formRef = doc(db, id, uid);

  const saveToFirebase = async () => {
    if (saving) return;
    setSaving(true);
    const newData = { ...formData };
    newData.modified = Timestamp.now();
    newData.form_id = id;
    newData.email = email;
    newData.status = "applied"

    try {
      await setDoc(formRef, newData);
      setApplied(true)
      setSaving(false);
    } catch (error) {
      console.log("error saving data", error);
      setSaving(false);
    }
  };

  const submit = () => {
    const errors = {};
    let valid = true;
    Object.keys(formData).forEach((e) => {
      if (form[e].required && !formData[e]) {
        errors[e] = true;
        valid = false;
      }
    });
    setErr(errors);
    if (!valid) return;
    saveToFirebase();
  };

  return applied ? (
    <div className="apply"><h2>Thank you for applying</h2></div>
  ) : (
    <div className="apply">
      <h3>Are we a match? Apply now!</h3>
      <p>
        <i>
          Please carefully fill out this form. Note that information provided
          may be shared with our community publicly so omit any information you
          do not wish to be shared openly. Your email address will never be
          shared.
        </i>
      </p>
      <Input
        form={form.fit}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.fit.required && "fit" in err && err.fit}
      />
      <Input
        form={form.nickname}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.nickname.required && "nickname" in err && err.nickname}
      />
      <Input
        form={form.age}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.age.required && "age" in err && err.age}
      />
      <Input
        form={form.country}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.country.required && "country" in err && err.country}
      />
      <Textarea
        form={form.launguages}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={
          form.launguages.required && "launguages" in err && err.launguages
        }
      />
      <Input
        form={form.resume}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.resume.required && "resume" in err && err.resume}
      />
      <Textarea
        form={form.samples}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.samples.required && "samples" in err && err.samples}
      />
      <Textarea
        form={form.highlights}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={
          form.highlights.required && "highlights" in err && err.highlights
        }
      />
      <Textarea
        form={form.why_hire}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.why_hire.required && "why_hire" in err && err.why_hire}
      />
      <Input
        form={form.linkedin}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.linkedin.required && "linkedin" in err && err.linkedin}
      />
      <Textarea
        form={form.socials}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.socials.required && "socials" in err && err.socials}
      />
      <Input
        form={form.start_date}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={
          form.start_date.required && "start_date" in err && err.start_date
        }
      />
      <Input
        form={form.hours}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.hours.required && "hours" in err && err.hours}
      />
      <Textarea
        form={form.additional_info}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={
          form.additional_info.required &&
          "additional_info" in err &&
          err.additional_info
        }
      />
      <Input
        form={form.share_approval}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={
          form.share_approval.required &&
          "share_approval" in err &&
          err.share_approval
        }
      />
      <Input
        form={form.salary}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={form.salary.required && "salary" in err && err.salary}
      />
      <Textarea
        form={form.additional_info_private}
        formData={formData}
        dirty={dirty}
        setDirty={() => setDirty(true)}
        setFormData={(values) => setFormData(values)}
        isErr={
          form.additional_info_private.required &&
          "additional_info_private" in err &&
          err.additional_info_private
        }
      />
      <button onClick={submit} disabled={saving}>{saving ? "Sending" : "Submit"}</button>
    </div>
  );
};
export default JobApply;
