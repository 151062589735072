import { GETFORM_URL } from "../config";
export const sendMail = async (data = "an email has been sent with no data attached") => {

  const url = GETFORM_URL;
  const formData = new FormData();
  formData.append("msg", data);
  try {
    await fetch(url, {
      method: "POST",
      body: formData,
    });
  } catch (err) {
    console.log(err);
  }

  return true;
};
