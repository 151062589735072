import ErrorMsg from "./ErrorMsg";
import Label from "./Label";
import Options from "./Options";
import Wrapper from "./Wrapper";

const Select = ({ form, formData, dirty, setDirty, setFormData, isErr }) => {
  return (
    <Wrapper>
      <Label formName={form.name} formLabel={form.label} />
      <div className="input-wrap">
        <select
          name={form.name}
          id={form.name}
          value={formData[form.name]}
          onChange={(e) => {
            !dirty && setDirty(true);
            setFormData({ ...formData, [form.name]: e.target.value });
          }}
        >
          <Options optionsData={form.options} />
        </select>
        <ErrorMsg errTxt={form.error} isErr={isErr} />
      </div>
    </Wrapper>
  );
};
export default Select;
