export const GETFORM_URL =
  "https://getform.io/f/582c37c7-bc74-4721-830e-7aeb7ed88f9e";
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyC0Kq-Pc6fj5bXukfm0l0TQ79SsVKpIYUE",
  authDomain: "project-application-673b0.firebaseapp.com",
  projectId: "project-application-673b0",
  storageBucket: "project-application-673b0.appspot.com",
  messagingSenderId: "608640825403",
  appId: "1:608640825403:web:685cc4589afd8cfa6b041e"
};
export const ADMINS = [
  "anMg5sSFS8VoNC7bK8FZv3BgIe02",
  "MtBLihXxCVUBoICGgpzzTtt8Dqh1",
  "jb5lh3L1A9flm4YaaL3mydRoqax1",
  "XELfdtcbeNa749QxZ8LCMieJ5hj1"
]