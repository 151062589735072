import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logInWithEmailAndPassword, signInWithGoogle } from "../firebase";
import { motion } from "framer-motion";
import { animateMain } from "../utils/animations";
import "./Login.scss";
import BackButton from "../components/BackButton/BackButton";

const Login = ({back = true}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <motion.div
      className="login__container card"
      initial={animateMain.initial}
      animate={animateMain.animate}
      exit={animateMain.exit}
      transition={animateMain.duration}
    >
      {back && <BackButton />}
      <h3>LOGIN</h3>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button onClick={() => logInWithEmailAndPassword(email, password)}>
        Login
      </button>
      <div>- OR -</div>
      <button className="login__google" onClick={signInWithGoogle}>
        Login with Google
      </button>
      <hr />
      <div>
        <Link to="/reset">Forgot Password</Link>
      </div>
      <div>
        Don't have an account? <Link to="/register">Register</Link> now.
      </div>
    </motion.div>
  );
};
export default Login;
