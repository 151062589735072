import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";

import "./Spreadsheet.scss";
import CapData from "./CapData";
import { allocationType, allocationTypeSorted } from "../../../lib/allocationType";
import PieChart from "../Charts/PieChart";
import AreaChart from "../Charts/AreaChart";

const Spreadsheet = ({
  formData,
  addRow = false,
  setFormData = false,
  uid = false,
  displayOnly = false
}) => {
  const [saving, setSaving] = useState(false);
  const table = formData.tokenomics_spreadsheet;
  const formRef = uid && doc(db, "ProjectFunding", uid);

  const deleteFromFirebase = async (newData) => {
    if (!setFormData) return;
    setSaving(true);
    try {
      await updateDoc(formRef, {
        tokenomics_spreadsheet: newData.tokenomics_spreadsheet,
      });
      setFormData(newData);
      setSaving(false);
    } catch (error) {
      console.log("error saving data", error);
      setSaving(false);
    }
  };

  const handleDelete = (key) => {
    if (saving || !setFormData) return;
    const newTable = { ...formData.tokenomics_spreadsheet };
    delete newTable[key];
    const newData = { ...formData, tokenomics_spreadsheet: newTable };
    deleteFromFirebase(newData);
  };

  let tAmount = 0;
  let tInitial = 0;
  let tRaised = 0;
  let maxs = 0;
  let tPresale = 0;
  let tTeamControl = 0;
  let tCommunity = 0
  let tBurn = 0
  let tLiquidity = 0
  let tRaise = 0
  const chartData = {
    labels: [],
    data: [],
    vesting: []
  }

  const tableDisplay = !table ? (
    <></>
  ) : (
    allocationTypeSorted.map((key) => {
      if(!(key in table)) return false
      const t = table[key];
      const amount = Number(t.amount) ? Number(t.amount) : 0;
      const unlock = Number(t.initial_unlock) ? Number(t.initial_unlock) : 0;
      const price = Number(t.price) ? Number(t.price) : 0;
      const raised = Number(t.raised) ? Number(t.raised) : 0;
      tRaised += (price * raised * amount) / 100;
      tPresale += allocationType[key].presale ? amount : 0;
      tTeamControl += allocationType[key].team_control ? amount : 0;
      tCommunity += allocationType[key].community ? amount : 0;
      tBurn += key === "Token Burn" ? amount : 0
      tLiquidity += key === "Liquidity" ? amount : 0
      tRaise += allocationType[key].presale ? amount * price : 0;
      chartData.labels.push(key)
      chartData.data.push(amount)
      chartData.vesting.push({
        init: t.initial_unlock,
        cliff: t.clif,
        vest: t.vesting
      })
      tAmount += amount;
      tInitial += key === "Liquidity" || key === "Token Burn" ? 0 : (unlock * amount) / 100;
      maxs = Number(formData.supply_max)
        ? Number(formData.supply_max)
        : tAmount;
      return (
        <tr key={key}>
          <td className="t-allocation">{key}</td>
          <td className="t-amount">{amount.toLocaleString()}</td>
          <td className="t-perc">{((amount * 100) / maxs).toFixed(2)}%</td>
          <td className="t-init">{t.initial_unlock}%</td>
          <td className="t-clif">{t.clif}</td>
          <td className="t-vest">{t.vesting}</td>
          <td className="t-price">{price && `$ ${price}`}</td>
          <td className="t-raise">{price && `$ ${(price * amount).toLocaleString()}`}</td>
          <td className="t-raised">{raised && `${raised}%`}</td>
          <td className="t-raised">
            {raised &&
              `$ ${((raised * price * amount) / 100).toLocaleString()}`}
          </td>
          <td className="t-val">$ {price && `${(Number(formData.supply_max) ? Number(formData.supply_max) * price : "NA").toLocaleString()}`}</td>
          <td className="t-delete">
            <FontAwesomeIcon
              className={saving ? "disabled" : "active"}
              onClick={() => handleDelete(key)}
              icon={faTrashCan}
              size="xl"
            />
          </td>
        </tr>
      );
    })
  );

  return (
    <>
      <table id="tokenomics-table" className={`${displayOnly ? "display" : ""}`}>
        <thead>
          <tr>
            <th className="t-allocation">Token Allocation</th>
            <th className="t-amount">Token Amount</th>
            <th className="t-perc">Allocation %</th>
            <th className="t-init">Initial Unlock %</th>
            <th className="t-clif">Cliff</th>
            <th className="t-vest">Vesting</th>
            <th className="t-price">$ Price</th>
            <th className="t-raise">$ Raise</th>
            <th className="t-raised">% Raised</th>
            <th className="t-raised">$ Raised</th>
            <th className="t-val">Valuation</th>
            <th className="t-delete">Action</th>
          </tr>
        </thead>
        <tbody>
          {tableDisplay}
          <tr className="summary">
            <td className="t-allocation"></td>
            <td className="t-amount">
              {tAmount > 0 && tAmount.toLocaleString()}
            </td>
            <td className="t-perc">
              {(Number(tAmount) * 100) / maxs > 0 &&
                `${((Number(tAmount) * 100) / maxs).toFixed(2)}%`}
            </td>
            <td className="t-init">
              {(tInitial * 100) / maxs > 0 &&
                `${((tInitial * 100) / maxs).toFixed(2)}%`}
            </td>
            <td className="t-clif"></td>
            <td className="t-vest"></td>
            <td className="t-price"></td>
            <td className="t-raise">$ {tRaise.toLocaleString()}</td>
            <td className="t-raised"></td>
            <td className="t-raised">
              {tRaised > 0 && `$ ${tRaised.toLocaleString()}`}
            </td>
            <td className="t-val"></td>
            <td className="t-delete"></td>
          </tr>
        </tbody>
      </table>
      {addRow && (
        <div className="icon-row">
          <FontAwesomeIcon
            className="icon-button"
            onClick={addRow}
            icon={faCirclePlus}
            size="2xl"
          />
        </div>
      )}
      <CapData
        formData={formData}
        maxCaclulated={tAmount}
        initialTokens={tInitial}
        presale={tPresale}
        teamControl={tTeamControl}
        community={tCommunity}
        liquidity={tLiquidity}
        burn={tBurn}
        raised={tRaised}
      />
      <div className="pie"><PieChart data={chartData} /></div>
      <div className="area"><AreaChart data={chartData} /></div>
    </>
  );
};
export default Spreadsheet;
