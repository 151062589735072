import { useState } from "react";
import { allocationType } from "../../../lib/allocationType";
import Input from "../../Forms/Input";
import Select from "../../Forms/Select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import "./SpreadsheetInput.scss";

const emptyState = {
  allocation_type: "",
  amount: "",
  initial_unlock: "",
  clif: "",
  vesting: "",
  price: "",
  raised: "",
};

const SpreadsheetInput = ({ formData, setFormData, open, close, setDirty }) => {
  const [localDirty, setLocalDirty] = useState(false);
  const [error, isError] = useState(false)
  const [values, setValues] = useState(emptyState);
  const typeOptions = Object.keys(allocationType);

  const forms = {
    typeForm: {
      label: "Token Allocation Type",
      name: "allocation_type",
      type: "select",
      options: typeOptions,
    },
    amount: {
      label: "Token Amount",
      name: "amount",
      type: "number",
      placeholder: "...for this allocation",
    },
    unlock: {
      label: "Initial Unlock %",
      name: "initial_unlock",
      type: "number",
      placeholder: "?%",
    },
    clif: {
      label: "Cliff",
      name: "clif",
      type: "number",
      placeholder: "",
    },
    vesting: {
      label: "Vesting",
      name: "vesting",
      type: "number",
      placeholder: "",
    },
    price: {
      label: "Price",
      name: "price",
      type: "number",
      placeholder: "?$",
    },
    raised: {
      label: "% of the raise filled",
      name: "raised",
      type: "number",
      placeholder: "% raised in this allocation",
    },
  };

  const validate = () => {
    isError(false)
    const validRegular =
      values.allocation_type !== "" &&
      values.amount !== "" &&
      values.initial_unlock !== "" &&
      values.clif !== "" &&
      values.vesting !== "" &&
      !isNaN(Number(values.amount)) &&
      !isNaN(Number(values.initial_unlock)) &&
      !isNaN(Number(values.clif)) &&
      !isNaN(Number(values.vesting)) &&
      Number(values.initial_unlock) <= 100;

    const validPresale = allocationType[values.allocation_type]?.presale
      ? values.price !== "" &&
        values.raised !== "" &&
        !isNaN(Number(values.price)) &&
        !isNaN(Number(values.raised)) &&
        Number(values.raised) <= 100
      : true;

      isError(!(validRegular && validPresale))
    return validRegular && validPresale
  };

  const addRow = () => {
    const oldData = formData?.tokenomics_spreadsheet
      ? { ...formData.tokenomics_spreadsheet }
      : {};
    const newData = {
      ...oldData,
      [values.allocation_type]: {
        ...values,
      },
    };
    setFormData({
      ...formData,
      tokenomics_spreadsheet: newData,
    });
  };

  const handleAddRow = () => {
    const valid = validate();
    if (!valid) return;
    addRow();
    setValues(emptyState);
    setDirty(true);
    close();
  };

  document.onkeydown = (e) => {
    if (!open) return;
    if (e.key === "Enter") return handleAddRow();
  };

  const formDisplay = Object.keys(forms).map((key) => {
    if (
      !allocationType[values.allocation_type]?.presale &&
      (key === "price" || key === "raised")
    )
      return false;
    switch (forms[key].type) {
      case "select":
        const optionsDifference =
          formData?.tokenomics_spreadsheet === undefined
            ? forms[key].options
            : forms[key].options.filter(
                (x) => !Object.keys(formData.tokenomics_spreadsheet).includes(x)
              );
        forms[key].options = optionsDifference;

        return (
          <Select
            key={key}
            form={forms[key]}
            formData={values}
            dirty={localDirty}
            setDirty={(v) => setLocalDirty(v)}
            setFormData={(v) => setValues(v)}
            isErr={false}
          />
        );
      default:
        return (
          <Input
            key={key}
            form={forms[key]}
            formData={values}
            dirty={localDirty}
            setDirty={(v) => setLocalDirty(v)}
            setFormData={(v) => setValues(v)}
            isErr={false}
          />
        );
    }
  });

  return (
    <div id="add-new-row-wrap" className={open ? "open" : "closed"}>
      <div className="add-new-row">
        {formDisplay}
        {error && <div className="form-err center">Something is wrong with the form. Check carefuly!</div>}
        <div className="icon-row">
          <FontAwesomeIcon
            className="icon-button"
            onClick={close}
            icon={faCircleXmark}
          />
          <FontAwesomeIcon
            className="icon-button green"
            onClick={handleAddRow}
            icon={faCircleCheck}
          />
        </div>
      </div>
    </div>
  );
};
export default SpreadsheetInput;
