import { useState } from "react";
import { logout } from "../../firebase";
import "./Header.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserNinja,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Header = ({ email, icon, loggedIn, setLoggedIn, isAdmin }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <header className="App-header">
      <div id="logo">
        <img className="pointer" src="/logo192.png" alt="metfi logo" onClick={() => navigate("/")} />
      </div>
      <div>Partner with MetFi DAO</div>
      <div className="user-info">
        {loggedIn ? (
          <>
            <span className="pointer" onClick={() => setOpen(!open)}>
              {icon ? (
                <img className="user-icon" src={icon} alt="user icon" />
              ) : (
                <FontAwesomeIcon icon={faUserNinja} size="lg" />
              )}
            </span>
            <div className={`user-menu ${open ? "open" : "closed"}`}>
              <li>{email}</li>
              {isAdmin && (
                <>
                  <li
                    className="pointer"
                    onClick={() => {
                      setOpen(false);
                      navigate("/admin");
                    }}
                    >
                    Admin
                  </li>
                  <li
                    className="pointer"
                    onClick={() => {
                      setOpen(false);
                      navigate("/jobsadmin");
                    }}
                    >
                    Job Applications Admin
                  </li>
                </>
              )}
              <li
                className="pointer"
                onClick={() => {
                  setOpen(false);
                  navigate("/");
                }}
              >
                Home
              </li>
              <li
                className="pointer"
                onClick={() => {
                  setOpen(false);
                  logout();
                  setLoggedIn(false);
                  navigate("/login")
                }}
              >
                Logout
              </li>
            </div>
          </>
        ) : (
          <div className="pointer" onClick={() => navigate("/login")}><FontAwesomeIcon icon={faRightToBracket} size="lg" /></div>
        )}
      </div>
    </header>
  );
};
export default Header;
