import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Header from "./components/Header/Header";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Reset from "./Pages/ResetPassword";
import Admin from "./Pages/Admin";
import ProjectFunding from "./Pages/ProjectFunding";
import { AnimatePresence } from "framer-motion";

import "./App.scss";
import "react-notifications-component/dist/theme.css";
import Home from "./Pages/Home";
import ProjectFundingPreview from "./Pages/ProjectFundingPreview";
import PositionsPreview from "./Pages/PositionsPreview";
import Position from "./Pages/Position";

import { ADMINS } from "./config";
import JobsAdmin from "./Pages/JobsAdmin";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (user) {
      if (loggedIn) return;
      setLoggedIn(true);
      setIsAdmin(ADMINS.includes(user.uid));
    } else {
      if (!loggedIn) return;
      setLoggedIn(false);
    }
  }, [user]);

  return (
    <div className="App">
      <ReactNotifications />
      <Header
        email={user && user.email}
        icon={user && user.photoURL}
        loggedIn={loggedIn}
        setLoggedIn={(v) => setLoggedIn(v)}
        isAdmin={isAdmin}
      />
      <main>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            {!loggedIn ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/jobs" element={<PositionsPreview />} />
                <Route path="/job/:id" element={<Position uid={false} />} />
                <Route path="/project-funding" element={<Login />} />
                <Route path="*" element={<Home />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/jobs" element={<PositionsPreview />} />
                <Route path="/job/:id" element={<Position uid={user.uid} email={user.email} />} />
                <Route path="/project-funding" element={<ProjectFundingPreview uid={user.uid} />} />
                <Route path="/project-funding/edit" element={<ProjectFunding uid={user.uid} />} />
                {isAdmin && 
                  <>
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/jobsadmin" element={<JobsAdmin />} />
                  </>
                }
                <Route path="*" element={<Home />} />
              </>
            )}
          </Routes>
        </AnimatePresence>
      </main>
    </div>
  );
};

export default App;
