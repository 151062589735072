export const tokenomicsSpreadsheet = {
  launch_price: {
    label: "Launch price:",
    type: "number",
    name: "launch_price",
    placeholder: "0.00000001$",
    required: true,
    error: "Insert your token launch price",
  },
  vesting_type: {
    label: "Vesting type:",
    type: "select",
    name: "vesting_type",
    options: [
      "-",
      "Monthly",
      "Weekly",
      "Continuous (per block)",
      "Price Vesting",
      "MC vesting",
      "No vesting",
      "Other",
    ],
    required: true,
    error: "Select vesting type",
  },
  vesting_other: {
    label: "Explain your vesting:",
    type: "textarea",
    name: "vesting_other",
    placeholder: "Vesting.....",
    required: true,
    error: "Please explain your vesting",
  },
};
