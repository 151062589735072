export const data = [
  {
    noStore: true,
    title: "Apply for Project Funding",
    content:
      "<p>Welcome to MetFi DAO.</p><p>Our vision is to invest in and incubate the next wave of Web3 unicorns!</p><p>If you’re seeking project funding, you’re in the right place.</p><p>We not only have substantial funds to invest - we also have the expertise and network to incubate your project and help you execute your vision more efficiently. Incubation is optional – if you don’t require or desire incubation we are happy to be an investor and a fan of your project and let you do what you do best.</p><p>Your application is strictly confidential, only a select few, very trusted team members will see your application, and your information will never be shared with a third party without your express pre-approval.</p><p>After we have reviewed your application, we will reach out to you if we have questions or are interested in progressing further. If you haven’t received a reply within 14 days it is because we are backlogged or your application was unsuccessful.</p><p><strong>Application Form</strong></p><p>Please complete each field accurately, to the best of your knowledge with the most up-to-date information available to you. If a field does not apply to the stage of your project simply type N/A and if you think it may not be clear to us why it isn’t applicable please explain why.</p><p>Your application is saved automatically when you click to the next page. You can manually save by clicking the save icon. You can re-visit your saved application at any time. We do not allow file uploads. If you want to send a file please provide us with a downloadable link.</p><p>We can’t wait to read your application so let’s get started.",
  },
  {
    title: "General",
    forms: [
      {
        label: "Project Name:",
        type: "text",
        name: "project_name",
        placeholder: "insert project name",
        required: true,
        error: "Please enter Project Name",
      },
      {
        label: "Website URL:",
        type: "url",
        name: "website_url",
        placeholder: "https://your-cool-project.crypto",
        required: true,
        error: "Please enter a valid URL",
      },
      {
        label: "Contact Email:",
        type: "email",
        name: "contact_email",
        placeholder: "your@email.com",
        required: true,
        error: "Please enter a valid Email",
      },
      {
        label: "Whitepaper Link:",
        type: "url",
        name: "wp_link",
        placeholder: "https://whitepaper.link",
        required: true,
        error: "Please enter a valid link to your Whitepaper",
      },
      {
        label: "Blockchain Network:",
        type: "select",
        name: "network",
        required: true,
        options: [
          "-",
          "BSC",
          "Ethereum",
          "Solana",
          "Polygon",
          "Optimism",
          "Tron",
          "Algorand",
          "Avalanche",
          "Near",
          "Fantom",
          "Cardano",
          "Polkadot",
          "Other",
        ],
        error: "Please select your Network",
        dependency: {
          Other: [
            {
              label: "Other Network:",
              type: "text",
              name: "network_other",
              placeholder: "Your Blockchain Network",
              required: true,
              error: "Please enter your Blockchain Network",
            },
          ],
        },
      },
    ],
  },
  {
    title: "Project",
    forms: [
      {
        label: "Organisation Type:",
        type: "select",
        name: "organisation_type",
        required: true,
        options: ["-", "Legal Entity (inc, llc, etc)", "DAO", "Bunch of bruhs"],
        error: "Please select your Organisation type",
        dependency: {
          "Legal Entity (inc, llc, etc)": [
            {
              label: "Place of incorporation:",
              type: "text",
              name: "organisation_type_place",
              placeholder: "Place of incorporation",
              required: true,
              error: "Please enter your place of incorporation",
            },
          ],
        },
      },
      {
        label: "Project Niche:",
        type: "select",
        name: "niche",
        required: true,
        options: [
          "-",
          "DeFi",
          "NFT",
          "Metaverse",
          "Gaming",
          "Layer-1",
          "Layer-2",
          "Web3",
          "Privacy",
          "Other",
        ],
        error: "Please select your project niche",
        dependency: {
          Other: [
            {
              label: "Other Niche:",
              type: "text",
              name: "niche_other",
              placeholder: "Your project niche",
              required: true,
              error: "Please enter your project niche",
            },
          ],
        },
      },
      {
        label: "Describe your project in one sentence:",
        type: "textarea",
        name: "description_sentence",
        placeholder: "Short project description...",
        required: true,
        error: "Please enter a short project description",
      },
      {
        label: "What problem are you solving?",
        type: "textarea",
        name: "problem",
        placeholder: "Our project is...",
        required: true,
        error: "Please describe what problem you are solving",
      },
      {
        label: "Who are your main competitors and how do you differ?",
        type: "textarea",
        name: "competitors",
        placeholder: "Our competitors are...",
        required: true,
        error: "Please describe your competitors",
      },
    ],
  },
  {
    title: "Roadmap",
    forms: [
      {
        label: "Roadmap (insert text or share a link):",
        type: "textarea",
        name: "roadmap",
        placeholder: "Roadmap...",
        required: true,
        error: "Please share your roadmap",
      },
      {
        label:
          "Key milestones achieved and key milestones you will complete within 12 months if we fund you:",
        type: "textarea",
        name: "milestones",
        placeholder: "Milestones...",
        required: true,
        error: "Please share your milestones",
      },
      {
        label: "If you have a working product or demo please give us access:",
        type: "textarea",
        name: "demo",
        placeholder: "Demo access credentials...",
        required: false,
        error: "Please share demo access",
      },
      {
        label:
          "Share the latest, most important statistics relative to your project e.g. Daily Active Users, Revenue (monthly, quarterly, annual), or other relevant statistics:",
        type: "textarea",
        name: "statistics",
        placeholder: "Statistics...",
        required: false,
        error: "Please share some statistics",
      },
    ],
  },
  {
    title: "Team",
    forms: [
      {
        label:
          "Where are the majority of the core team located? Office, remote?",
        type: "textarea",
        name: "team_location",
        placeholder: "Team location...",
        required: true,
        error: "Please share your team location",
      },
      {
        label: "Is the team DOXXed:",
        type: "radio",
        name: "dox",
        options: ["Yes", "No"],
        required: true,
        error: "Are you DOXXed?",
        dependency: {
          Yes: [
            {
              label: "Founder/CEO bio including LinkedIn profile:",
              type: "textarea",
              name: "founder",
              placeholder: "Founder of the project...",
              required: true,
              error: "Please enter details of the founder",
            },
            {
              label: "Core team member bio’s including LinkedIn profiles:",
              type: "textarea",
              name: "team_members",
              placeholder: "Core team members...",
              required: true,
              error: "Please enter details of the team members",
            },
          ],
        },
      },
      {
        label: "Number of developers including level of expertise:",
        type: "textarea",
        name: "developers",
        placeholder: "Our developers...",
        required: true,
        error: "Please share details about your developers",
      },
      {
        label:
          "Advisory Board bio’s, their roles/expertise, and LinkedIn profiles:",
        type: "textarea",
        name: "advisory_board",
        placeholder: "Advisory board...",
        required: false,
        error: "Please share advisory board details",
      },
    ],
  },
  {
    title: "Token",
    forms: [
      {
        label: "Is your token live?",
        type: "radio",
        name: "token_live",
        options: ["Yes", "No"],
        required: true,
        error: "Is the token live?",
        dependency: {
          Yes: [
            {
              label: "Token ticker:",
              type: "text",
              name: "token_ticker",
              placeholder: "XYZ",
              required: true,
              error: "Please enter the token ticker",
            },
            {
              label: "Link to blockchain explorer:",
              type: "url",
              name: "blockchain_explorer",
              placeholder: "https://bscscan.com/token/.....",
              required: true,
              error: "Please enter the link to your token explorer",
            },
            {
              label: "Coingecko link:",
              type: "url",
              name: "coingecko",
              placeholder: "https://coingecko.com/en/coins/.....",
              required: false,
              error: "Please enter Coingecko link",
            },
            {
              label: "CMC link:",
              type: "url",
              name: "cmc",
              placeholder: "https://coinmarketcap.com/currencies/.....",
              required: false,
              error: "Please enter CMC link",
            },
          ],
          No: [
            {
              label: "Expected token launch:",
              type: "date",
              name: "expected_launch_date",
              required: true,
              error: "Please estimate your launch date",
            },
          ],
        },
      },
      {
        label: "Has your code been audited?",
        type: "radio",
        name: "audit",
        options: ["Yes", "No"],
        required: true,
        error: "Have you been audited?",
        dependency: {
          Yes: [
            {
              label: "Link to the audit report:",
              type: "url",
              name: "audit_report_link",
              placeholder: "https://my-audit-report.....",
              required: true,
              error: "Please enter link to audit",
            },
          ],
          No: [
            {
              label: "Do you plan to get audited? Please explain:",
              type: "textarea",
              name: "audit_plans",
              placeholder: "Plans about the audit...",
              required: true,
              error: "What are your plans about the audit?",
            },
          ],
        },
      },
      {
        label:
          "Did you or do you plan to renounce ownership of the smart conracts?",
        type: "radio",
        name: "renounce_ownership",
        options: ["Yes", "No"],
        required: true,
        error: "Do you plan to renounce ownership?",
      },
      {
        label:
          "What is the token utility? How will the token be used?",
        type: "textarea",
        name: "token_utility",
        placeholder: "Token utility...",
        required: true,
        error: "Please share the token utility",
      },
    ],
  },
  {
    title: "Tokenomics (min - max ticket size)",
    forms: [
      {
        label: "Supply type:",
        type: "radio",
        name: "supply_type",
        options: ["Fixed", "Unlimited"],
        required: true,
        error: "Please select token supply type",
        dependency: {
          Fixed: [
            {
              label: "Maximum supply tokens:",
              type: "number",
              name: "supply_max",
              placeholder: "Gazillion!",
              required: true,
              error: "Please enter how many tokens will be minted",
            },
          ],
          Unlimited: [
            {
              label: "Starting Token Supply:",
              type: "number",
              name: "supply_max",
              placeholder: "Gazillion!",
              required: true,
              error: "Please enter how many tokens will be minted",
            },
            {
              label:
                "Token mint policy. Please explain in detail who and how can mint new tokens:",
              type: "textarea",
              name: "supply_mint_policy",
              placeholder: "Who can mint new tokens",
              required: true,
              error: "Please enter how and who can mint new tokens",
            },
          ],
        },
      },
      {
        type: "tokenomics"
      },
      { type: "pass", name: "launch_price"}
    ],
  },
  {
    title: "Funding",
    forms: [
      {
        label: "How does the project generate revenue?",
        type: "textarea",
        name: "revenue_model",
        placeholder: "Revenue model...",
        required: false,
        error: "Please share your revenue model",
      },
      {
        label: "Name your largest token holders?",
        type: "textarea",
        name: "large_token_holders",
        placeholder: "Largest token holders...",
        required: false,
        error: "Please share details on your largest token holders",
      },
      {
        label: "How do you intend to use raised funds?",
        type: "textarea",
        name: "funds_usage",
        placeholder: "Fund usage...",
        required: true,
        error: "Please share details on how you intend to use the funds",
      },
      {
        label: "How do you manage the safety of the funds?",
        type: "textarea",
        name: "funds_safety",
        placeholder: "Fund safety...",
        required: false,
        error:
          "Please share details on how you intend to assure the safety of the funds",
      },
    ],
  },
  {
    title: "Token launch",
    forms: [
      {
        label: "Are you working with a market maker?",
        type: "radio",
        name: "market_maker",
        options: ["Yes", "No"],
        required: true,
        error: "Are you working with a market maker?",
        dependency: {
          Yes: [
            {
              label: "Details:",
              type: "textarea",
              name: "market_maker_details",
              placeholder: "Our market maker is...",
              required: true,
              error: "Please provide details on your market maker",
            },
          ],
        },
      },
      {
        label: "Do you have any agreement with token launchpads?",
        type: "radio",
        name: "launchpads",
        options: ["Yes", "No"],
        required: true,
        error: "Will you launch on launchpads?",
        dependency: {
          Yes: [
            {
              label: "Launchpads details (also provide proof):",
              type: "textarea",
              name: "launchpad_details",
              placeholder: "Launchpads...",
              required: true,
              error: "Please provide details on launchpads",
            },
          ],
        },
      },
      {
        label: "Do you have any agreements with centralized exchanges?",
        type: "radio",
        name: "cex",
        options: ["Yes", "No"],
        required: true,
        error: "Will you launch on any CEX?",
        dependency: {
          Yes: [
            {
              label: "CEX details (also provide proof):",
              type: "textarea",
              name: "cex_details",
              placeholder: "CEXes...",
              required: true,
              error: "Please provide details on CEXes",
            },
          ],
        },
      },
      {
        label: "Which DEXes do you intend to launch on?",
        type: "textarea",
        name: "dex",
        placeholder: "DEXes...",
        required: true,
        error: "Please share details on what DEX you will launch your token",
      },
    ],
  },
  {
    title: "Token launch",
    forms: [
      {
        label: "Do you have anti-bot protection in place?",
        type: "radio",
        name: "bots",
        options: ["Yes", "No"],
        required: true,
        error: "Do you handle bots?",
        dependency: {
          Yes: [
            {
              label: "Anti-bot strategy:",
              type: "textarea",
              name: "bot_strategy",
              placeholder: "Anti bot strategy...",
              required: true,
              error: "Please provide details on your bot protection",
            },
          ],
        },
      },
      {
        label: "Do you have a sales tax implemented into the token?",
        type: "radio",
        name: "salex_tax",
        options: ["Yes", "No"],
        required: true,
        error: "Do you have a sales tax?",
        dependency: {
          Yes: [
            {
              label: "Sales tax (in %):",
              type: "number",
              name: "sales_tax_percentage",
              placeholder: "Sales tax percentage...",
              required: true,
              error: "Please provide sales tax %",
            },
          ],
        },
      },
      {
        label:
          "How much liquidity will be provided for the initial listing (include only the stable part of the liquidity in USD)?",
        type: "number",
        name: "liquidity",
        placeholder: "Liquidity...",
        required: false,
        error: "Please provide liquidity details",
      },
      {
        label: "Will the liquidity be locked?",
        type: "radio",
        name: "liquidity_lock",
        options: ["Yes", "No"],
        required: true,
        error: "Will you lock liquidity?",
        dependency: {
          Yes: [
            {
              label: "How much? (in %):",
              type: "number",
              name: "liquidity_lock_percentage",
              placeholder: "Liquidity lock percentage...",
              required: true,
              error: "Please provide liqudity lock %",
            },
            {
              label: "For how long will you lock liqudity? (in months):",
              type: "number",
              name: "liquidity_lock_months",
              placeholder: "Liquidity lock duration in months...",
              required: true,
              error: "Please provide liqudity lock duration",
            },
            {
              label: "How do you intend to lock liquidity?",
              type: "txtarea",
              name: "liquidity_lock_method",
              placeholder: "Liquidity lock method...",
              required: false,
              error: "Please provide liqudity lock method",
            },
          ],
        },
      },
    ],
  },
  {
    title: "MetFi",
    forms: [
      {
        label:
          "What is the size of the investment you are seeking from MetFi? (in USD):",
        type: "number",
        name: "mfi_investment",
        placeholder: "We would like MetFi DAO to invest XXX...",
        required: true,
        error: "Please provide details on how much ivestment you are seeking",
      },
      {
        label: "At what price?",
        type: "number",
        name: "mfi_investment_price",
        placeholder: "MetFi investment offer...",
        required: true,
        error: "Please provide details on your offer for MetFi",
      },
      {
        label:
          "Do you think you can offer any additional value to the MetFi community?",
        type: "textarea",
        name: "community_benefits",
        placeholder: "MetFi additional benefits...",
        required: false,
        error: "Please provide details on your additinal benefits for MetFi",
      },
      {
        label: "Are you seeking incubation with the investment?",
        type: "radio",
        name: "incubation",
        options: ["Yes", "No"],
        required: true,
        error: "Do you need incubation?",
        dependency: {
          Yes: [
            {
              label: "Specify the areas you want help with?",
              type: "textarea",
              name: "incubation_details",
              placeholder: "We need help with...",
              required: true,
              error: "Please provide details on incubation needs",
            },
          ],
        },
      },
    ],
  },
  {
    title: "Marketing",
    forms: [
      {
        label: "Describe your marketing plan:",
        type: "textarea",
        name: "marketing_plan",
        placeholder: "Our marketing plan...",
        required: true,
        error: "Please provide details on your marketing plan",
      },
      {
        label: "Are you focusing on any specific geographic or demographic?",
        type: "radio",
        name: "demographics",
        options: ["Yes", "No"],
        required: true,
        error: "Do you focus on any demographics?",
        dependency: {
          Yes: [
            {
              label: "Please provide some details:",
              type: "textarea",
              name: "demographics_details",
              placeholder: "Our focus is on...",
              required: true,
              error: "Please provide details on focused demo/geo-graphics",
            },
          ],
        },
      },
      {
        label:
          "How will you gain the interest and trust in the broader crypto community?",
        type: "textarea",
        name: "community_trust",
        placeholder: "Because we are so awesome...",
        required: true,
        error: "Please provide details on how you will gain interest and trust",
      },
      {
        label:
          "What is your approximate marketing budget for the next year? (in USD):",
        type: "number",
        name: "marketing_budget",
        placeholder: "Million Gazillion...",
        required: false,
        error: "Marketing budget?",
      },
      {
        label:
          "Name your strategic partners, briefly introduce your partnerships, your relationship, what the partner has committed to, and a link to any announcements or proof of your partnership:",
        type: "textarea",
        name: "partnerships",
        placeholder: "Our partners...",
        required: false,
        error: "Please provide details on your partnerships",
      },
      {
        label: "Are you working with any KOLs?",
        type: "radio",
        name: "kol",
        options: ["Yes", "No"],
        required: true,
        error: "Key Opinion Leaders?",
        dependency: {
          Yes: [
            {
              label: "Who are you working with? To what extend? Any proof?",
              type: "textarea",
              name: "kol_details",
              placeholder: "KOLs are kool...",
              required: true,
              error: "Please provide details on your influencers",
            },
          ],
        },
      },
    ],
  },
  {
    title: "Socials",
    forms: [
      {
        label: "Telegram Link:",
        type: "url",
        name: "social_tg_link",
        placeholder: "TG link...",
        required: false,
        error: "Please provide your TG link",
      },
      {
        label: "Telegram Member Count:",
        type: "number",
        name: "social_tg_number",
        placeholder: "TG members...",
        required: false,
        error: "Please provide your TG member number",
      },
      {
        label: "Twitter Link:",
        type: "url",
        name: "social_tw_link",
        placeholder: "TW link...",
        required: false,
        error: "Please provide your TW link",
      },
      {
        label: "Twitter Followers:",
        type: "number",
        name: "social_tw_number",
        placeholder: "TW followers...",
        required: false,
        error: "Please provide your TW follower number",
      },
      {
        label: "Discord Link:",
        type: "url",
        name: "social_discord_link",
        placeholder: "Discord link...",
        required: false,
        error: "Please provide your Discord link",
      },
      {
        label: "Discord Members:",
        type: "number",
        name: "social_discord_number",
        placeholder: "Discord members...",
        required: false,
        error: "Please provide your Discord member number",
      },
      {
        label: "Medium Link:",
        type: "url",
        name: "social_medium_link",
        placeholder: "Medium link...",
        required: false,
        error: "Please provide your Medium link",
      },
      {
        label: "Medium Followers:",
        type: "number",
        name: "social_medium_number",
        placeholder: "Medium followers...",
        required: false,
        error: "Please provide your Medium follower number",
      },
      {
        label: "YouTube/Vimeo/Twitch Link:",
        type: "textarea",
        name: "social_video_link",
        placeholder: "Video platforms link...",
        required: false,
        error: "Please provide your links to video platforms",
      },
      {
        label: "Github Link:",
        type: "url",
        name: "social_git_link",
        placeholder: "GitHub link...",
        required: false,
        error: "Please provide your GitHub link",
      },
    ],
  },
];
