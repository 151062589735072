const Options = ({optionsData}) => {
  const options = optionsData.map((option) => (
    <option key={option} value={option}>
      {option}
    </option>
  ));

  return options;
};
export default Options;
