import { useEffect, useState } from "react";
import { getDocs, collection, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";

import "./Admin.scss";
import Spreadsheet from "../components/Tokenomics/Spreadsheet/Spreadsheet";

const Admin = () => {
  const [formData, setFormData] = useState({});
  const [reload, setReload] = useState(0);
  const [selectedProject, setSelectedProject] = useState("");

  const dbRef = query(collection(db, "ProjectFunding"), orderBy("modified", "desc"));

  useEffect(() => {
    const newData = { ...formData };
    const getData = async () => {
      const querySnapshot = await getDocs(dbRef);
      querySnapshot.forEach((doc) => {
        newData[doc.id] = doc.data();
      });
      setFormData(newData);
    };
    getData();
  }, [reload]);

  const displayList =
    formData &&
    Object.keys(formData).map((key) => {
      if (formData[key].form_status !== "filled") return;

      const entry = `${formData[key].project_name} - ${String(
        formData[key].modified.toDate()
      )}`;
      return (
        <option key={key} value={key}>
          {entry}
        </option>
      );
    });

  const d = formData[selectedProject];

  return (
    <div className="adminWrapper">
      <div className="selector">
        <button className="reload" onClick={() => setReload(reload + 1)}>
          <FontAwesomeIcon icon={faRotate} size="lg" />
        </button>
        <select onChange={(e) => setSelectedProject(e.target.value)}>
          <option value="">Select Project</option>
          {displayList}
        </select>
      </div>
      {d && (
        <div className="content">
          <hr />
          <h1>{d.project_name}</h1>
          <div className="wrap">
            <div className="label">Date modified</div>
            <div className="value">{String(d.modified.toDate())}</div>
          </div>
          <h2>General Information</h2>
          <div className="wrap">
            <div className="label">Website URL</div>
            <div className="value"><a href={d.website_url} target="_blank" rel="noreferrer">{d.website_url}</a></div>
          </div>
          <div className="wrap">
            <div className="label">Contact Email</div>
            <div className="value"><a href={`mailto:${d.contact_email}`} target="_blank"  rel="noreferrer">{d.contact_email}</a></div>
          </div>
          <div className="wrap">
            <div className="label">Whitepaper Link</div>
            <div className="value"><a href={d.wp_link} target="_blank"  rel="noreferrer">{d.wp_link}</a></div>
          </div>
          <div className="wrap">
            <div className="label">Blockchain Network</div>
            <div className="value">{d.network_other ? d.network_other : d.network}</div>
          </div>
          <div className="wrap">
            <div className="label">Organisation Type</div>
            <div className="value">{d.organisation_type}</div>
          </div>
          {
            d.organisation_type_place && (
              <div className="wrap">
                <div className="label">Place of incorporation</div>
                <div className="value">{d.organisation_type_place}</div>
              </div>
            )
          }
          <h2>Project Details</h2>
          <div className="wrap">
            <div className="label">Project Niche</div>
            <div className="value">{d.niche_other ? d.niche_other : d.niche}</div>
          </div>
          <div className="wrap">
            <div className="label">Short Description</div>
            <div className="value">{d.description_sentence}</div>
          </div>
          <div className="wrap">
            <div className="label">The Problem</div>
            <div className="value">{d.problem}</div>
          </div>
          <div className="wrap">
            <div className="label">Competition</div>
            <div className="value">{d.competitors}</div>
          </div>
          <h2>Roadmap</h2>
          <div className="wrap">
            <div className="label">Project Roadmap</div>
            <div className="value">{d.roadmap}</div>
          </div>
          <div className="wrap">
            <div className="label">Short-term Milestones</div>
            <div className="value">{d.milestones}</div>
          </div>
          <div className="wrap">
            <div className="label">Demo Access</div>
            <div className="value">{d.demo}</div>
          </div>
          <div className="wrap">
            <div className="label">Project Stats</div>
            <div className="value">{d.statistics}</div>
          </div>
          <h2>Team</h2>
          <div className="wrap">
            <div className="label">Team Location</div>
            <div className="value">{d.team_location}</div>
          </div>
          {
            d.dox === "No" ? (
              <div className="wrap">
                <div className="label">Team DOXXed?</div>
                <div className="value">{d.dox}</div>
              </div>
            ) : (
              <>
                <div className="wrap">
                  <div className="label">Founder/CEO</div>
                  <div className="value">{d.founder}</div>
                </div>
                <div className="wrap">
                  <div className="label">Other Core Team Members</div>
                  <div className="value">{d.team_members}</div>
                </div>        
              </>
            ) 
          }
          <div className="wrap">
            <div className="label">Number of Developers and Their Expertise</div>
            <div className="value">{d.developers}</div>
          </div>
          <div className="wrap">
            <div className="label">Advisory Board</div>
            <div className="value">{d.advisory_board}</div>
          </div>
          <h2>The Token</h2>
          {
            d.token_live === "No" ? (
              <div className="wrap">
                <div className="label">Expected Token Launch</div>
                <div className="value">{new Date(d.expected_launch_date).toDateString()}</div>
              </div>
            ) : (
              <>
                <div className="wrap">
                  <div className="label">Token Ticker</div>
                  <div className="value">{d.token_ticker}</div>
                </div>
                <div className="wrap">
                  <div className="label">Blockchain Explorer Link</div>
                  <div className="value"><a href={d.blockchain_explorer} target="blank" rel="noreferrer">{d.blockchain_explorer}</a></div>
                </div>
                <div className="wrap">
                  <div className="label">CoinGecko</div>
                  <div className="value"><a href={d.coingecko} target="blank" rel="noreferrer">{d.coingecko}</a></div>
                </div>
                <div className="wrap">
                  <div className="label">CoinMarketCap</div>
                  <div className="value"><a href={d.cmc} target="blank" rel="noreferrer">{d.cmc}</a></div>
                </div>
              </>
            ) 
          }
          <div className="wrap">
            <div className="label">Supply Type</div>
            <div className="value">{d.supply_type}</div>
          </div>
          {
            d.supply_type === "Unlimited" && (
              <div className="wrap">
                <div className="label">Token Mint Policy</div>
                <div className="value">{d.supply_mint_policy}</div>
              </div>
            ) 
          }
          {
            d.audit === "Yes" ? (
              <div className="wrap">
                <div className="label">Audit Report</div>
                <div className="value"><a href={d.audit_report_link} target="_blank" rel="noreferrer">{d.audit_report_link}</a></div>
              </div>
            ) : (
              <div className="wrap">
                <div className="label">Audit Plans</div>
                <div className="value">{d.audit_plans}</div>
              </div>
            ) 
          }
          <div className="wrap">
            <div className="label">Smart Contract Ownership Renouncement</div>
            <div className="value">{d.renounce_ownership}</div>
          </div>
          <div className="wrap">
            <div className="label">Token utility</div>
            <div className="value">{d.token_utility}</div>
          </div>
          <h2>Tokenomics</h2>
          {
            d.supply_type === "Fixed" && (
              <div className="wrap">
                <div className="label">Max Supply</div>
                <div className="value">{Number(d.supply_max).toLocaleString()}</div>
              </div>
            )
          }
          <div className="wrap">
            <div className="label">Launch Price</div>
            <div className="value">$ {d.launch_price}</div>
          </div>
          <div className="wrap">
            <div className="label">Vesting Type</div>
            <div className="value">{d.vesting_type === "Other" ? d.vesting_other : d.vesting_type}</div>
          </div>
          <br />
          <Spreadsheet formData={d} displayOnly={true} />
          <h2>Funding</h2>
          <div className="wrap">
            <div className="label">Revenue Model</div>
            <div className="value">{d.revenue_model}</div>
          </div>
          <div className="wrap">
            <div className="label">Large Token Holders</div>
            <div className="value">{d.large_token_holders}</div>
          </div>
          <div className="wrap">
            <div className="label">Funs Usage</div>
            <div className="value">{d.funds_usage}</div>
          </div>
          <div className="wrap">
            <div className="label">Funs Safety Management</div>
            <div className="value">{d.funds_safety}</div>
          </div>
          <h2>Token Launch</h2>
          <div className="wrap">
            <div className="label">Market Maker</div>
            <div className="value">{d.market_maker === "No" ? "Not working with a market maker" : d.market_maker_details }</div>
          </div>
          <div className="wrap">
            <div className="label">Launchpads</div>
            <div className="value">{d.launchpads === "No" ? "No launchpads" : d.launchpad_details }</div>
          </div>
          <div className="wrap">
            <div className="label">Centralized Exchanges</div>
            <div className="value">{d.cex === "No" ? "No CEX" : d.cex_details }</div>
          </div>
          <div className="wrap">
            <div className="label">Decentralized Exchanges</div>
            <div className="value">{d.dex }</div>
          </div>
          <div className="wrap">
            <div className="label">Anti-bot protection</div>
            <div className="value">{d.bots === "No" ? "No bpt protection" : d.bot_strategy }</div>
          </div>
          <div className="wrap">
            <div className="label">Sales Tax</div>
            <div className="value">{d.salex_tax === "No" ? 0 : d.sales_tax_percentage }%</div>
          </div>
          <div className="wrap">
            <div className="label">Initial Liquidity</div>
            <div className="value">$ {Number(d.liquidity).toLocaleString()}</div>
          </div>
          {
            d.liquidity_lock === "No" ? (
              <div className="wrap">
                <div className="label">Liquidity Lock</div>
                <div className="value">No liqudity locking</div>
              </div>
            ) : (
              <>
                <div className="wrap">
                  <div className="label">Total Liquidity Lock</div>
                  <div className="value">{d.liquidity_lock_percentage}%</div>
                </div>
                <div className="wrap">
                  <div className="label">Total Liquidity Lock</div>
                  <div className="value">{d.liquidity_lock_percentage}%</div>
                </div>
                <div className="wrap">
                  <div className="label">Liquidity Lock Duration</div>
                  <div className="value">{d.liquidity_lock_months} months</div>
                </div>
                <div className="wrap">
                  <div className="label">Locking Method</div>
                  <div className="value">{d.liquidity_lock_method}</div>
                </div>
              </>
            ) 
          }
          <h2>MetFi Involvement</h2>
          <div className="wrap">
            <div className="label">Sought Investment Size</div>
            <div className="value">$ {Number(d.mfi_investment).toLocaleString()}</div>
          </div>
          <div className="wrap">
            <div className="label">Investment Token Price</div>
            <div className="value">$ {Number(d.mfi_investment_price).toLocaleString()}</div>
          </div>
          <div className="wrap">
            <div className="label">Additional MetFi Community Benefits</div>
            <div className="value">{d.community_benefits}</div>
          </div>
          <div className="wrap">
            <div className="label">Incubation Needs</div>
            <div className="value">{d.incubation === "No" ? "No incubation needs" : d.incubation_details}</div>
          </div>
          <h2>Marketing</h2>
          <div className="wrap">
            <div className="label">Marketing Plan</div>
            <div className="value">{d.marketing_plan}</div>
          </div>
          <div className="wrap">
            <div className="label">Geographic or Demographic Focus</div>
            <div className="value">{d.demographics === "No" ? "No specific focus" : d.demographics_details}</div>
          </div>
          <div className="wrap">
            <div className="label">Gaining Broader Crypto Community Trust</div>
            <div className="value">{d.community_trust}</div>
          </div>
          <div className="wrap">
            <div className="label">Marketin Budget For The Next Year</div>
            <div className="value">$ {Number(d.marketing_budget).toLocaleString()}</div>
          </div>
          <div className="wrap">
            <div className="label">Partnerships</div>
            <div className="value">{d.partnerships}</div>
          </div>
          <div className="wrap">
            <div className="label">Influencers/KOLs</div>
            <div className="value">{d.kol === "No" ? "No KOLs" : d.kol_details}</div>
          </div>
          <h2>Socials</h2>
          <div className="wrap">
            <div className="label">Telegram</div>
            <div className="value">
              <a href={d.social_tg_link} target="_blank" rel="noreferrer">{d.social_tg_link}</a> ({d.social_tg_number} members)
            </div>
          </div>
          <div className="wrap">
            <div className="label">Twitter</div>
            <div className="value">
              <a href={d.social_tw_link} target="_blank" rel="noreferrer">{d.social_tw_link}</a> ({d.social_tw_number} followers)
            </div>
          </div>
          <div className="wrap">
            <div className="label">Discord</div>
            <div className="value">
              <a href={d.social_discord_link} target="_blank" rel="noreferrer">{d.social_discord_link}</a> ({d.social_discord_number} members)
            </div>
          </div>
          <div className="wrap">
            <div className="label">Medium</div>
            <div className="value">
              <a href={d.social_medium_link} target="_blank" rel="noreferrer">{d.social_medium_link}</a> ({d.social_medium_number} followers)
            </div>
          </div>
          <div className="wrap">
            <div className="label">Video Platforms</div>
            <div className="value">{d.social_video_link}</div>
          </div>
          <div className="wrap">
            <div className="label">GitHub</div>
            <div className="value">{d.social_git_link}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Admin;