import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BackButton from "../components/BackButton/BackButton";
import JobApply from "../components/JobApply/JobApply";
import positions from "../lib/openPositions.json";
import Login from "./Login";

import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

import "./PositionsPreview.scss";

const Position = ({ uid, email }) => {
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);
  const [applied, setApplied] = useState(false);
  const [status, setStatus] = useState(false);
  const { id } = useParams();
  const p = positions[id];

  useEffect(() => {
    readFromFirebase();
  }, []);

  const readFromFirebase = async () => {
    try {
      const docRef = doc(db, id, uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setApplied(docSnap.data().modified);
        setStatus(docSnap.data().status);
        setFirebaseLoaded(true);
      } else {
        setFirebaseLoaded(true);
      }
      
    } catch (error) {
      setFirebaseLoaded(true);
    }
  };

  return (
    <div className="position-detail content">
      <BackButton />
      <h1>{p.title}</h1>
      {applied && (
        <div className="position center">
          You already applied for this position on {String(applied.toDate())}.
          <p>
            <strong>
              {status === "denied" ? (
                <>
                  We are sorry to inform you that your application has not been
                  accepted.
                </>
              ) : (
                <>Please check back here later for the status of your application.</>
              )}
            </strong>
          </p>
        </div>
      )}
      <p>
        <strong>Location:</strong> {p.location}
      </p>
      {p.description.map((p) => (
        <p key={p}>{p}</p>
      ))}
      <p>
        <strong>{p.responsibilities.title}:</strong>
      </p>
      <ul>
        {p.responsibilities.items.map((i) => (
          <li key={i}>{i}</li>
        ))}
      </ul>
      {p.requirements.map((req) => (
        <Fragment key={req.title}>
          <p>
            <strong>{req.title}:</strong>
          </p>
          <ul>
            {req.items.map((i) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </Fragment>
      ))}
      {p.end.map((p) => (
        <p key={p}>{p}</p>
      ))}
      {uid ? (firebaseLoaded &&
        !applied &&
        <JobApply id={id} uid={uid} email={email} />
      ) : (
        <div className="apply">
          <h3>To apply for this possition please login</h3>
          <Login back={false} />
        </div>
      )}
    </div>
  );
};
export default Position;
