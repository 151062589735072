import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { chartColors } from '../../../lib/allocationType';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({data}) => {
    const colors1 = data.labels.map(i => chartColors[i][0])
    const colors2 = data.labels.map(i => chartColors[i][1])
    const pieData = {
        labels: data.labels,
        datasets: [
          {
            label: 'Token Allocations',
            data: data.data,
            backgroundColor: colors1,
            borderColor: colors2,
            borderWidth: 1,
          },
        ],
      };
    return <Pie data={pieData} />
}
export default PieChart