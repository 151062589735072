import { useState } from "react";
import Spreadsheet from "../Tokenomics/Spreadsheet/Spreadsheet";
import Input from "./Input";
import Wrapper from "./Wrapper";
import { tokenomicsSpreadsheet as form } from "../../lib/tokenomicsSpreadsheet";
import SpreadsheetInput from "../Tokenomics/SpreadsheetInput/SpreadsheetInput";
import Select from "./Select";
import Textarea from "./Textarea";

const Tokenomics = ({ formData, dirty, setDirty, setFormData, isErr, uid }) => {
  const [addRowOpen, setAddRowOpen] = useState(false);

  return (
    <>
      <Wrapper>
        <div className="div-label">Supply type:</div>
        <div className="input-wrap">
          {formData?.supply_type
            ? formData.supply_type
            : "Supply type not yet set"}
        </div>
        <div className="div-label">Max supply:</div>
        <div className="input-wrap">
          {formData?.supply_max
            ? Number(formData.supply_max).toLocaleString()
            : "Max supply not yet set"}
        </div>
      </Wrapper>
      <Input
        form={form.launch_price}
        formData={formData}
        dirty={dirty}
        setDirty={(v) => setDirty(v)}
        setFormData={(v) => setFormData(v)}
        isErr={isErr}
      />
      <Select
        form={form.vesting_type}
        formData={formData}
        dirty={dirty}
        setDirty={(v) => setDirty(v)}
        setFormData={(v) => setFormData(v)}
        isErr={isErr}
      />
      {formData?.vesting_type === "Other" && <Textarea
        form={form.vesting_other}
        formData={formData}
        dirty={dirty}
        setDirty={(v) => setDirty(v)}
        setFormData={(v) => setFormData(v)}
        isErr={isErr}
      />}
      <SpreadsheetInput
        formData={formData}
        setFormData={(v) => setFormData(v)}
        open={addRowOpen}
        close={() => setAddRowOpen(false)}
        setDirty={(v) => setDirty(v)}
      />
      <Spreadsheet
        formData={formData}
        setFormData={(v) => setFormData(v)}
        addRow={() => setAddRowOpen(true)}
        uid={uid}
      />
    </>
  );
};
export default Tokenomics;
