import ErrorMsg from "./ErrorMsg";
import Label from "./Label";
import Wrapper from "./Wrapper";

const Radio = ({ form, formData, dirty, setDirty, setFormData, isErr }) => {
  const options = form.options.map((option) => (
    <div key={option} className="radio-wrap">
      <Label
        formName={`${form.name}_${option}`}
        formLabel={option}
      />
      <input
        type={form.type}
        name={form.name}
        id={`${form.name}_${option}`}
        placeholder={form.placeholder}
        value={option}
        checked={option === formData[form.name]}
        onChange={(e) => {
          !dirty && setDirty(true);
          setFormData({ ...formData, [form.name]: e.target.value });
        }}
      />
    </div>
  ));
  return (
    <Wrapper>
      <div className="div-label">{form.label}</div>
      <div className="input-wrap">
        {options}
        <ErrorMsg errTxt={form.error} isErr={isErr} />
      </div>
    </Wrapper>
  );
};
export default Radio;
