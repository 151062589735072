import { useEffect, useState } from "react";
import { getDocs, collection, orderBy, query, setDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

import positions from "../lib/openPositions.json";

import "./Admin.scss";

const JobsAdmin = () => {
  const [reload, setReload] = useState(0);
  const [selectedJob, setSelectedJob] = useState(false);
  const [selectedAplication, setSelectedAplication] = useState(false);
  const [applications, setApplications] = useState({});
  const [saving, setSaving] = useState(false);

  const dbRef =
    selectedJob &&
    query(collection(db, selectedJob), orderBy("modified", "desc"));

  useEffect(() => {
    if (!selectedJob) return;
    const newData = {};
    const getData = async () => {
      const querySnapshot = await getDocs(dbRef);
      querySnapshot.forEach((doc) => {
        newData[doc.id] = doc.data();
      });
      setApplications(newData);
    };
    getData();
  }, [selectedJob, reload]);

  const saveToFirebase = async (user, status) => {
    if (saving) return;
    setSaving(true);
    const saveRef = doc(db, selectedJob, user);
    try {
      await setDoc(saveRef, { status }, { merge: true });
      setReload(reload + 1)
      setSaving(false);
    } catch (error) {
      console.log("error saving data", error);
      setSaving(false);
    }
  };

  return (
    <div className="adminWrapper">
      <div className="select-job">
        {Object.keys(positions).map((job) => (
          <div
            key={job}
            onClick={() => {
              setSelectedAplication(false)
              setSelectedJob(job)
            }}
            className={`job${job === selectedJob ? " selected" : ""}`}
          >
            {positions[job].title}
          </div>
        ))}
      </div>
      <div className="table-wrap">
        <table className="applications">
          <tbody>
            <tr>
              <th>Date</th>
              <th>Nickname</th>
              <th>Age</th>
              <th>Country</th>
              <th>Languages</th>
              <th>LinkedIn</th>
              <th>Can Start</th>
            </tr>
            {Object.keys(applications).length > 0 ? (
              <>
                {Object.keys(applications).map((k) => {
                  if (applications[k].status === "denied") return;
                  return (
                    <tr key={k} onClick={() => setSelectedAplication(k)} className={k === selectedAplication ? "selected" : ""}>
                      <td>{applications[k].modified.toDate().toDateString()}</td>
                      <td>{applications[k].nickname}</td>
                      <td>{applications[k].age}</td>
                      <td>{applications[k].country}</td>
                      <td>{applications[k].launguages}</td>
                      <td>{applications[k].linkedin}</td>
                      <td>{applications[k].start_date.toLocaleString()}</td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <tr>
                <td colSpan={17}>No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {selectedAplication &&
        <div className="application-details">
          <h2>Details</h2>
          <div className="detail-wrap"><div className="detail-title">Date</div><div className="detail-content">{applications[selectedAplication].modified.toDate().toDateString()}</div></div>
          <div className="detail-wrap"><div className="detail-title">Nickname</div><div className="detail-content">{applications[selectedAplication].nickname}</div></div>
          <div className="detail-wrap"><div className="detail-title">Age</div><div className="detail-content">{applications[selectedAplication].age}</div></div>
          <div className="detail-wrap"><div className="detail-title">Country</div><div className="detail-content">{applications[selectedAplication].country}</div></div>
          <div className="detail-wrap"><div className="detail-title">Languages</div><div className="detail-content">{applications[selectedAplication].launguages}</div></div>
          <div className="detail-wrap"><div className="detail-title">CV/Resume</div><div className="detail-content">{applications[selectedAplication].resume}</div></div>
          <div className="detail-wrap"><div className="detail-title">Sample Work</div><div className="detail-content">{applications[selectedAplication].samples}</div></div>
          <div className="detail-wrap"><div className="detail-title">Career Highlights</div><div className="detail-content">{applications[selectedAplication].highlights}</div></div>
          <div className="detail-wrap"><div className="detail-title">Hire Because</div><div className="detail-content">{applications[selectedAplication].why_hire}</div></div>
          <div className="detail-wrap"><div className="detail-title">LinkedIn</div><div className="detail-content">{applications[selectedAplication].linkedin}</div></div>
          <div className="detail-wrap"><div className="detail-title">other Socials</div><div className="detail-content">{applications[selectedAplication].socials}</div></div>
          <div className="detail-wrap"><div className="detail-title">Can Start</div><div className="detail-content">{applications[selectedAplication].start_date.toLocaleString()}</div></div>
          <div className="detail-wrap"><div className="detail-title">Availability</div><div className="detail-content">{applications[selectedAplication].hours}</div></div>
          <div className="detail-wrap"><div className="detail-title">Additional Info</div><div className="detail-content">{applications[selectedAplication].additional_info}</div></div>
          <div className="detail-wrap"><div className="detail-title">Salary</div><div className="detail-content">{applications[selectedAplication].salary}</div></div>
          <div className="detail-wrap"><div className="detail-title">Info for Admins</div><div className="detail-content">{applications[selectedAplication].additional_info_private}</div></div>
          <div className="detail-wrap"><div className="detail-title">Actions</div><div className="detail-content"><button onClick={() => saveToFirebase(selectedAplication, "denied")}>Deny</button></div></div>
        </div>
      }
    </div>
  );
};
export default JobsAdmin;
