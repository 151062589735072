import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate()
  return (
    <div className="content">
      <h1>Welcome to MetFi DAO Application Forms</h1>
      <div className="wrap">
        <div className="col">
          <h3>Careers at MetFi DAO</h3>
          <p>
            We offer competitive salaries with opportunities for growth and professional development.
          </p>
          <button onClick={() => navigate("/jobs")}>View job openings and apply to work at MetFi DAO.</button>
        </div>
        <div className="col">
          <h3>Fund your Web3 Project</h3>
          <p>
            We fund and incubate Web3 startups.
          </p>
          <button onClick={() => navigate("/project-funding")}>Apply for Funding</button>
        </div>
      </div>
    </div>
  );
};
export default Home;
