import ErrorMsg from "./ErrorMsg";
import Label from "./Label";
import Wrapper from "./Wrapper";

const Input = ({ form, formData, dirty, setDirty, setFormData, isErr }) => {
  return (
    <Wrapper reverse={form.type === "checkbox"}>
      <Label formName={form.name} formLabel={`${form.label}${form.required ? "*" : ""}`} />
      <div className="input-wrap">
        <input
          type={form.type}
          name={form.name}
          id={form.name}
          placeholder={form.placeholder}
          value={formData[form.name]}
          onChange={(e) => {
            !dirty && setDirty(true);
            setFormData({ ...formData, [form.name]: form.type === "checkbox" ? e.target.checked : e.target.value });
          }}
        />
        <ErrorMsg errTxt={form.error} isErr={isErr} />
      </div>
    </Wrapper>
  );
};
export default Input;
