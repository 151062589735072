import "./PositionsPreview.scss";

import positions from "../lib/openPositions.json";
import { useNavigate } from "react-router-dom";

const PositionsPreview = () => {
  const navigate = useNavigate();
  return (
    <div className="content">
      <h1>We are Hiring</h1>
      <h3>If you’re looking for a career, not just a job, you’re in the right place.</h3>
      <p>
        Our innovative blockchain project is at the forefront of the DeFi, GameFi, and Metaverse space and we are looking to add talented and motivated professionals to our team.
      </p>
      <strong>Open positions</strong>
      {Object.keys(positions).map(
        (key) =>
          positions[key].open && (
            <div
              key={key}
              onClick={() => navigate(`/job/${key}`)}
              className="position pointer"
            >
              {positions[key].title}
            </div>
          )
      )}
    </div>
  );
};
export default PositionsPreview;
