import React, { useState } from "react";
import { Store } from "react-notifications-component";
import { Link } from "react-router-dom";
import { registerWithEmailAndPassword, signInWithGoogle } from "../firebase";
import { motion } from "framer-motion";
import { animateMain } from "../utils/animations";
import "./Login.scss";

const addNotification = (message, type) => {
  Store.addNotification({
    message,
    type,
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const register = () => {
    if (!email) return addNotification("Please enter email", "info");
    if (!password) return addNotification("Please enter password", "info");
    registerWithEmailAndPassword(email, password);
  };

  return (
    <motion.div
      className="login__container card"
      initial={animateMain.initial}
      animate={animateMain.animate}
      exit={animateMain.exit}
      transition={animateMain.duration}
    >
      <h3>REGISTER</h3>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button onClick={register}>Register</button>
      <div>- OR -</div>
      <button className="login__google" onClick={signInWithGoogle}>
        Register with Google
      </button>
      <hr />
      <div>
        Already have an account? <Link to="/login">Login</Link> now.
      </div>
    </motion.div>
  );
}
export default Register;
