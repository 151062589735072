import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormStatus from "../components/FormStatus/FormStatus";
import { sendMail } from "../utils/getForm";
import "./ProjectFunding.scss";

import { getDoc, setDoc, Timestamp, doc } from "firebase/firestore";
import { db } from "../firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faFloppyDisk,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";

import { data } from "../lib/projectFunding";
import Input from "../components/Forms/Input";
import Select from "../components/Forms/Select";
import Textarea from "../components/Forms/Textarea";
import Radio from "../components/Forms/Radio";
import Tokenomics from "../components/Forms/Tokenomics";

const ProjectFunding = ({ uid }) => {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({});
  const [init, setInit] = useState(false);
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [saving, setSaving] = useState(false);
  const [validationData, setValidationData] = useState(null);

  const formRef = doc(db, "ProjectFunding", uid);

  const navigate = useNavigate();

  useEffect(() => {
    readFromFirebase();
  }, []);

  useEffect(() => {
    if (!init || !firebaseLoaded || !data) return;
    let newStatus = "Saved";
    if (dirty) newStatus = "Unsaved changes";
    if (saving) newStatus = "Saving";
    setFormStatus(newStatus);
  }, [dirty, saving]);

  useEffect(() => {
    if (!data || !("forms" in data[page]) || !firebaseLoaded) return;
    const newState = { ...formData };

    data[page].forms.forEach((form) => {
      if (!(form.name in formData)) newState[form.name] = "";
      if (form?.dependency) {
        Object.keys(form.dependency).forEach((depKey) => {
          form.dependency[depKey].forEach((dep) => {
            if (!(dep.name in formData)) newState[dep.name] = "";
          });
        });
      }
    });

    setFormData(newState);
    setInit(true);
  }, [data, page, firebaseLoaded]);

  const readFromFirebase = async () => {
    const docSnap = await getDoc(formRef);
    if (docSnap.exists()) {
      setFormData(docSnap.data());
      setFirebaseLoaded(true);
    } else {
      console.log("couldnt fetch firebase doc");
      setFirebaseLoaded(true);
    }
  };

  const validateForm = () => {
    if (!dirty) return true;
    let val = false;
    const newValidationData = [];
    data[page].forms.forEach((form) => {
      if (formData?.[form.name] === "" && form.required)
        newValidationData.push(form.name);
      if (form?.dependency) {
        Object.keys(form.dependency).forEach((depKey) => {
          if (formData[form.name] !== depKey) return;
          form.dependency[depKey].forEach((dep) => {
            if (formData?.[dep.name] === "" && dep.required)
              newValidationData.push(dep.name);
          });
        });
      }
    });
    setValidationData(newValidationData);
    if (newValidationData.length < 1) {
      val = true;
    }
    return val;
  };

  const saveToFirebase = async (stage) => {
    if ((!dirty || saving) && stage !== "finalize") return;
    setSaving(true);
    const newData = {} 
    Object.keys(formData).forEach((key) => {
      if (formData[key]) newData[key] = formData[key];
    });
    newData.modified = Timestamp.now()
    newData.form_status = stage === "finalize" ? "filled" : "started"
    
    try {
      await setDoc(formRef, newData, { merge: true });
      setDirty(false);
      if (stage === "finalize") {
        navigate("/project-funding")
        sendMail("a new funding application form has been submitted")
      };
    } catch (error) {
      console.log("error saving data", error);
    }
    setSaving(false);
  };

  const prev = () => {
    setInit(false);
    saveToFirebase();
    setPage(page - 1);
  };
  const next = () => {
    const val = validateForm();
    if (!val) return;
    setInit(false);
    saveToFirebase();
    setPage(page + 1);
  };
  const finalize = () => {
    const val = validateForm();
    if (!val) return;
    saveToFirebase("finalize");
  };

  document.onkeydown = (e) => {
    if (
      page < data.length - 1 &&
      (e.key === "ArrowDown" || e.key === "ArrowRight")
    )
      return next();
    if (page > 0 && (e.key === "ArrowUp" || e.key === "ArrowLeft"))
      return prev();
  };

  const getFormByType = (form, err) => {
    let formDisplay;
    switch (form.type) {
      case "pass":
        formDisplay = <></>;
        break;
      case "select":
        formDisplay = (
          <Select
            key={form.name}
            form={form}
            formData={formData}
            dirty={dirty}
            setDirty={(v) => setDirty(v)}
            setFormData={(v) => setFormData(v)}
            isErr={err}
          />
        );
        break;
      case "textarea":
        formDisplay = (
          <Textarea
            key={form.name}
            form={form}
            formData={formData}
            dirty={dirty}
            setDirty={(v) => setDirty(v)}
            setFormData={(v) => setFormData(v)}
            isErr={err}
          />
        );
        break;
      case "radio":
        formDisplay = (
          <Radio
            key={form.name}
            form={form}
            formData={formData}
            dirty={dirty}
            setDirty={(v) => setDirty(v)}
            setFormData={(v) => setFormData(v)}
            isErr={err}
          />
        );
        break;
      case "tokenomics":
        formDisplay = (
          <Tokenomics
            formData={formData}
            dirty={dirty}
            setDirty={(v) => setDirty(v)}
            setFormData={(v) => setFormData(v)}
            isErr={err}
            uid={uid}
          />
        );
        break;
      default:
        formDisplay = (
          <Input
            key={form.name}
            form={form}
            formData={formData}
            dirty={dirty}
            setDirty={(v) => setDirty(v)}
            setFormData={(v) => setFormData(v)}
            isErr={err}
          />
        );
    }
    return formDisplay;
  };

  const forms =
    init &&
    "forms" in data[page] &&
    data[page].forms.map((form, i) => {
      const err = validationData && validationData.includes(form.name);
      const primaryForm = getFormByType(form, err);
      let dependencyForm;
      if (form?.dependency?.[formData[form.name]]) {
        dependencyForm = form.dependency[formData[form.name]].map((dep) => {
          const err = validationData && validationData.includes(dep.name);
          return getFormByType(dep, err);
        });
      }
      return (
        <span key={`${page}-${i}`}>
          {primaryForm} {dependencyForm}
        </span>
      );
    });

  return (
    <>
      <div key={page - 1} className={`card project-funding left`}></div>
      <div key={page} className={`card project-funding`}>
        <div className="card-content">
          <h3>{data[page].title}</h3>
          {"content" in data[page] && (
            <div dangerouslySetInnerHTML={{ __html: data[page].content }} />
          )}
          {init && forms && forms}
        </div>
        <div className="card-buttons">
          <div>
            {page > 0 && (
              <FontAwesomeIcon
                className="icon-button"
                onClick={prev}
                icon={faCircleChevronLeft}
                size="2xl"
              />
            )}
          </div>
          <div>
            {page < data.length - 1 ? (
              <FontAwesomeIcon
                className="icon-button"
                onClick={next}
                icon={faCircleChevronRight}
                size="2xl"
              />
            ) : (
              <>
              
              <div className="finalize" onClick={finalize}>
                Send <FontAwesomeIcon icon={faFileImport} size="xl" />
              </div>
              <div className="finalize-text">Clicking Send will finalize your application form and you will
                not be able to edit it afterwards. If you wish to add any
                information to the form you can close the browser and continue
                later, your data has been saved.</div>
              </>
            )}
          </div>
        </div>
        {dirty && !saving && (
          <FontAwesomeIcon
            className="save-icon"
            onClick={!saving && saveToFirebase}
            icon={faFloppyDisk}
            size="2xl"
          />
        )}
      </div>
      <div key={page + 1} className={`card project-funding right`}></div>
      <FormStatus status={formStatus} />
    </>
  );
};
export default ProjectFunding;
