import Wrapper from "../../Forms/Wrapper";
import Label from "../../Forms/Label";

const CapData = ({
  formData,
  maxCaclulated,
  initialTokens,
  presale,
  teamControl,
  community,
  liquidity,
  burn,
  raised
}) => {
  const match = Number(formData.supply_max) === Number(maxCaclulated);
  const tSupply = Number(maxCaclulated) - Number(burn);

  const mc = (type) => {
    if (formData.supply_type === "Unlimited" && type === "total")
      return <div>Infinite</div>;
    if (!(formData.launch_price > 0)) return "Enter Launch Price";
    const warn = !match ? (
      <span className="warn">!Token supply doesn't match</span>
    ) : (
      ""
    );

    let display;
    if (type === "total") {
      display = (
        <>
          <div>{tSupply.toLocaleString()}</div>
          <div>
            ${" "}
            {(
              Number(formData.launch_price) *
              (Number(tSupply) - Number(burn))
            ).toLocaleString()}
          </div>
          <div>{warn}</div>
        </>
      );
    } else if (type === "initial" && initialTokens) {
      display = (
        <>
          <div>
            {Number(initialTokens).toLocaleString()}
          </div>
          <div>
            ${" "}
            {(
              Number(formData.launch_price) * Number(initialTokens)
            ).toLocaleString()}
          </div>
          <div>{((Number(initialTokens) * 100) / tSupply).toFixed(2)}%</div>
        </>
      );
    }

    return <>{display}</>;
  };

  return (
    <div className="cap-data">
      <Wrapper>
        <Label formName="title" formLabel="Description" />
        <div className="input-wrap">
          <div>in Tokens</div>  
          <div>in $</div>  
          <div>in %</div>  
          <div>Remarks</div>  
        </div>
      </Wrapper>
      <Wrapper>
        <Label formName="mc" formLabel="Total MC" />
        <div className="input-wrap">{mc("total")}</div>
      </Wrapper>
      <Wrapper>
        <Label formName="initial-mc" formLabel="Initial MC (without liquidity)" />
        <div className="input-wrap">{mc("initial")}</div>
      </Wrapper>
      <Wrapper>
        <Label formName="presale" formLabel="Raise (Investor Control)" />
        <div className="input-wrap">
          <div>{presale.toLocaleString()}</div>
          <div>$ {(presale * Number(formData.launch_price)).toLocaleString()}</div>
          <div>{((presale / tSupply) * 100).toFixed(2)}%</div>
          <div>Filled {(raised * 100 / (presale * Number(formData.launch_price))).toFixed(2)}%</div>
        </div>
      </Wrapper>
      <Wrapper>
        <Label formName="team_control" formLabel="Under Team Control" />
        <div className="input-wrap">
          <div>{teamControl.toLocaleString()}</div>
          <div>$ {(teamControl * Number(formData.launch_price)).toLocaleString()}</div>
          <div>{((teamControl / tSupply) * 100).toFixed(2)}%</div>
        </div>
      </Wrapper>
      <Wrapper>
        <Label formName="community" formLabel="Under Commuity Control" />
        <div className="input-wrap">
          <div>{community.toLocaleString()}</div>
          <div>$ {(community * Number(formData.launch_price)).toLocaleString()}</div>
          <div>{((community / tSupply) * 100).toFixed(2)}%</div>
        </div>
      </Wrapper>
      <Wrapper>
        <Label formName="liquidity" formLabel="Liquidity" />
        <div className="input-wrap">
          <div>{liquidity.toLocaleString()}</div>
          <div>$ {(liquidity * Number(formData.launch_price)).toLocaleString()}</div>
          <div>{((liquidity / tSupply) * 100).toFixed(2)}%</div>
        </div>
      </Wrapper>
      <Wrapper>
        <Label formName="token_burn" formLabel="Token Burn" />
        <div className="input-wrap">
          <div>{burn.toLocaleString()}</div>
          <div>$ {(burn * Number(formData.launch_price)).toLocaleString()}</div>
          <div>{((burn / tSupply) * 100).toFixed(2)}%</div>
        </div>
      </Wrapper>
      {/* Funding: Seed, Private, Public, IDO, Total
    Valuation: Seed, Private, IDO, Launch
    Free Float IDO: token, IDO price, % of supply
    Total Float IDO: token, IDO price, % of supply */}
    </div>
  );
};
export default CapData;
