import ErrorMsg from "./ErrorMsg";
import Label from "./Label";
import Wrapper from "./Wrapper";

const Textarea = ({ form, formData, dirty, setDirty, setFormData, isErr }) => {
  return (
    <Wrapper>
      <Label formName={form.name} formLabel={`${form.label}${form.required ? "*" : ""}`} />
      <div className="input-wrap">
        <textarea
          name={form.name}
          id={form.name}
          placeholder={form.placeholder}
          value={formData[form.name]}
          onChange={(e) => {
            !dirty && setDirty(true);
            setFormData({ ...formData, [form.name]: e.target.value });
          }}
        />
        <ErrorMsg errTxt={form.error} isErr={isErr} />
      </div>
    </Wrapper>
  );
};
export default Textarea;
