import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sendPasswordReset } from "../firebase";
import { motion } from "framer-motion";
import { animateMain } from "../utils/animations";
import "./Login.scss";

function Reset() {
  const [email, setEmail] = useState("");

  return (
    <motion.div
      className="login__container card"
      initial={animateMain.initial}
      animate={animateMain.animate}
      exit={animateMain.exit}
      transition={animateMain.duration}
    >
      <h3>RESET PASSWORD</h3>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <button onClick={() => sendPasswordReset(email)}>
        Send password reset email
      </button>
      <hr />
      <div>
        Don't have an account? <Link to="/register">Register</Link> now.
      </div>
    </motion.div>
  );
}
export default Reset;
