import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { chartColors, tokenReleaseLabels } from "../../../lib/allocationType";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

let labels = [];
const getDataset = (amount, set, maxDuration) => {
  labels = [];
  let finalAmount = 0;
  const init = Number(set.init) * Number(amount) / 100
  const increment =
    Number(set.vest) === 0
      ? Number(amount)
      : ((Number(amount) - init) / Number(set.vest));
  const dataSet = [];

  for (let i = 0; i <= maxDuration; i++) {
    labels.push(i);
    if (i === 0) {
      finalAmount += init;
    } else if (
      i > Number(set.cliff) &&
      i <= Number(set.cliff) + Number(set.vest)
    ) {
      finalAmount += increment;
    }
    dataSet.push(finalAmount);
  }

  return dataSet;
};

const AreaChart = ({ data }) => {
    // const data = {...datas, labels: [...datas.labels], data: [...datas.data], vesting: [...datas.vesting]}
//   data.labels.forEach((label, i) => {
//     if (label === "Token Burn") {
//       data.labels.splice(i,1);
//       data.data.splice(i,1);
//       data.vesting.splice(i,1);
//     }
//   });

  const maxDuration = () => {
    let duration = 0;
    data.vesting.forEach((e) => {
      duration =
        Number(e.cliff) + Number(e.vest) > duration
          ? Number(e.cliff) + Number(e.vest)
          : duration;
    });
    return duration;
  };

  const datasetsSingle = []
  tokenReleaseLabels.forEach((label, i) => {
    const index = data.labels.indexOf(label)
    if(index < 0) return
    const newSet = getDataset(data.data[index], data.vesting[index], maxDuration());
    datasetsSingle.push({
      fill: true,
      label: label,
      data: newSet,
      borderColor: chartColors[label][1],
      backgroundColor: chartColors[label][0],
    })
  });

  let prevDataSet = [];
  const datasets = datasetsSingle.map((set, i) => {
    const newSet = { ...set };
    if (!(i === 0)) {
      newSet.data = set.data.map((s, i) => Number(s) + Number(prevDataSet[i]));
    }
    prevDataSet = newSet.data;
    return newSet;
  });
  const chartData = {
    labels,
    datasets,
  };
  return <Line options={options} data={chartData} />;
};
export default AreaChart;
