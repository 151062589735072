export const allocationType = {
  "-": {},
  Seed: {
    presale: true,
    team_control: false,
    investor: true,
    community: false,
  },
  "Private 1": {
    presale: true,
    team_control: false,
    investor: true,
    community: false,
  },
  "Private 2": {
    presale: true,
    team_control: false,
    investor: true,
    community: false,
  },
  Public: {
    presale: true,
    team_control: false,
    investor: false,
    community: true,
  },
  Launchpads: {
    presale: true,
    team_control: false,
    investor: false,
    community: true,
  },
  Team: {
    presale: false,
    team_control: true,
    investor: false,
    community: false,
  },
  Advisors: {
    presale: false,
    team_control: false,
    investor: false,
    community: false,
  },
  "Strategic Partnerships": {
    presale: false,
    team_control: false,
    investor: false,
    community: false,
  },
  Marketing: {
    presale: false,
    team_control: true,
    investor: false,
    community: false,
  },
  Airdrops: {
    presale: false,
    team_control: false,
    investor: false,
    community: true,
  },
  "Ecosystem/Development": {
    presale: false,
    team_control: true,
    investor: false,
    community: false,
  },
  Staking: {
    presale: false,
    team_control: false,
    investor: false,
    community: true,
  },
  Liquidity: {
    presale: false,
    team_control: false,
    investor: false,
    community: false,
  },
  "Token Burn": {
    presale: false,
    team_control: false,
    investor: false,
    community: false,
  },
  "Community Rewards": {
    presale: false,
    team_control: false,
    investor: false,
    community: true,
  },
  "Private 3": {
    presale: true,
    team_control: false,
    investor: true,
    community: false,
  },
  "Private 4": {
    presale: true,
    team_control: false,
    investor: true,
    community: false,
  },
  "Private 5": {
    presale: true,
    team_control: false,
    investor: true,
    community: false,
  },
};

export const allocationTypeSorted = [
  "Seed",
  "Private 1",
  "Private 2",
  "Private 3",
  "Private 4",
  "Private 5",
  "Public",
  "Launchpads",
  "Team",
  "Advisors",
  "Ecosystem/Development",
  "Strategic Partnerships",
  "Marketing",
  "Airdrops",
  "Community Rewards",
  "Staking",
  "Token Burn",
  "Liquidity",
]

export const tokenReleaseLabels = [
  "Liquidity",
  "Staking",
  "Seed",
  "Private 1",
  "Private 2",
  "Private 3",
  "Private 4",
  "Private 5",
  "Public",
  "Launchpads",
  "Team",
  "Advisors",
  "Ecosystem/Development",
  "Strategic Partnerships",
  "Marketing",
  "Airdrops",
  "Community Rewards",
]

export const chartColors = {
  "Seed": ["rgba(178,167,207,.5)","rgba(178,167,207,1)"],
  "Private 1": ["rgba(232,169,195,.5)","rgba(232,169,195,1)"],
  "Private 2": ["rgba(219,139,171,.5)","rgba(219,139,171,1)"],
  "Private 3": ["rgba(210,108,149,.5)","rgba(210,108,149,1)"],
  "Private 4": ["rgba(199,77,127,.5)","rgba(199,77,127,1)"],
  "Private 5": ["rgba(189,33,111,.5)","rgba(189,33,111,1)"],
  "Public": ["rgba(116,182,85,.5)","rgba(116,182,85,1)"],
  "Launchpads": ["rgba(191,218,165,.5)","rgba(191,218,165,1)"],
  "Team": ["rgba(152,210,206,.5)","rgba(152,210,206,1)"],
  "Advisors": ["rgba(74,186,183,.5)","rgba(74,186,183,1)"],
  "Ecosystem/Development": ["rgba(227,230,138,.5)","rgba(227,230,138,1)"],
  "Strategic Partnerships": ["rgba(98,194,235,.5)","rgba(98,194,235,1)"],
  "Marketing": ["rgba(12,175,168,.5)","rgba(12,175,168,1)"],
  "Airdrops": ["rgba(240,141,106,.5)","rgba(240,141,106,1)"],
  "Community Rewards": ["rgba(244,167,136,.5)","rgba(244,167,136,1)"],
  "Staking": ["rgba(194,207,29,.5)","rgba(194,207,29,1)"],
  "Token Burn": ["rgba(254,227,188,.5)","rgba(254,227,188,1)"],
  "Liquidity": ["rgba(234,115,160,.5)","rgba(234,115,160,1)"],
}