import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

import './ProjectFundingPreview.scss'

const ProjectFundingPreview = ({ uid }) => {
  const [formData, setFormData] = useState({});
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);
  const formRef = doc(db, "ProjectFunding", uid);

  useEffect(() => {
    readFromFirebase();
  }, []);

  const readFromFirebase = async () => {
    const docSnap = await getDoc(formRef);
    if (docSnap.exists()) {
      setFormData(docSnap.data());
      setFirebaseLoaded(true);
    } else {
      console.log("couldnt fetch firebase doc");
      setFirebaseLoaded(true);
    }
  };

  let content = (
    <div className="content narrow">
      <h1>Welcome to the MetFi Funding Application Form</h1>
      <p>If you’re a project with Unicorn potential looking for funding you came to
      the right place. Please fill out our application form as thoroughly as
      possible to get started.</p>
      <div className="start-button-wrap center">
        <Link className="button" to="/project-funding/edit">
          {formData.form_status === "started" ? "Continue" : "Start"}
        </Link>
      </div>
    </div>
  );

  if(formData.form_status === "filled") {
    content = <div className="content narrow">
    <h1>Thank you for applying!</h1>
    <p>We have received your application and we will review it ASAP - we will be in touch if we’d like to know more. Please give us 14 days to review. If you haven’t heard from us by then it’s because we are backlogged or your application was not successful.</p>
    <p>You can edit your application at any time.</p>
    <div className="start-button-wrap center">
      <Link className="button" to="/project-funding/edit">
        Edit Form
      </Link>
    </div>
  </div>
  }

  return firebaseLoaded && content;
};
export default ProjectFundingPreview;
